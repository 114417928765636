// src/components/Footer.js
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import rightDecoration from '../assets/footer-right.svg';
import startButtonImage from '../assets/start.svg';

function Footer({ countdownEnded, countdownSectionRef }) {
  const handleClick = () => {
    if (countdownEnded) {
      // Open the link if the countdown has ended
      window.open("https://www.britannica.com/topic/European-Union", "_blank");
    } else if (countdownSectionRef.current) {
      // Scroll to the countdown section if the countdown is still active
      countdownSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (countdownEnded) {
      // Optionally, handle any action when countdown ends (e.g., updating UI)
    }
  }, [countdownEnded]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '100px',
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(3px)',
        borderRadius: '15px',
        fontFamily: "'Noto Sans Arabic', sans-serif",
        overflow: 'hidden',
      }}
    >
      {/* Right Decoration Image */}
      <Box component="img" src={rightDecoration} alt="Right Decoration" sx={{ position: 'absolute', right: 0, height: '100%', zIndex: 1 }} />

      {/* Button with Image */}
      <Button
        onClick={handleClick}
        sx={{
          backgroundColor: '#FBC029',
          borderRadius: '30px',
          padding: { xs: '15px 40px', md: '40px 120px' },
          zIndex: 2,
          mb: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            backgroundColor: '#e0a828',
          },
        }}
      >
        <Box component="img" src={startButtonImage} alt="Start" sx={{ width: { xs: '80px', md: '120px' }, height: 'auto' }} />
      </Button>

      {/* Explanatory Text */}
      <Typography variant="h5" sx={{ color: '#064DA1', fontWeight: 'bold', textAlign: 'center', mt: 2, zIndex: 2, fontSize: { xs: '1.5rem', md: '2rem' } }}>
        لتبدأ المسابقة اضغط هنا
      </Typography>
    </Box>
  );
}

export default Footer;
