// src/components/QuestionSection.js
import { Box, Typography } from '@mui/material';
import React from 'react';
import questionInfo from '../assets/question_info.svg'; // Third image
import questionImage from '../assets/question_section.svg'; // First image
import leftFlags from '../assets/right_flags.svg'; // Right-side image
import timeImage from '../assets/time_section.svg'; // Second image

function QuestionSection() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
        backdropFilter: 'blur(3px)', // Blurred effect
        zIndex: 10,
        position: 'relative', // Make the parent relative for absolute positioning of the flag
        fontFamily: "'Noto Sans Arabic', sans-serif", // Apply font family here
      }}
    >
      <Box
        sx={{
          width: '100%', // Adjust container width for responsiveness
          position: 'relative', // Make the container relative for the flag positioning
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={questionImage}
          alt="Question Section Decoration"
          style={{
            maxWidth: '1100px',
            width: '100%', // Responsive width for the main image
            height: 'auto', // Maintain aspect ratio
            marginBottom: '20px', // Add spacing
          }}
        />

        {/* Left Flags image positioned to the right, with adjustments for mobile screens */}
        <Box
          component="img"
          src={leftFlags}
          alt="Right Flags Decoration"
          sx={{
            position: 'absolute',
            right: { xs: '-15%', sm: '-5%' }, // Adjust position on mobile screens
            top: 0,
            height: '100%',
            width: { xs: '15%', sm: '20%', md: '10%' }, // Adjust width for responsiveness
            display: { xs: 'none', sm: 'block' }, // Hide on extra small screens
          }}
        />
      </Box>

      {/* Time Image */}
      <img
        src={timeImage}
        alt="Time Section Decoration"
        style={{
          width: '90%', // Responsive width for the second image
          maxWidth: '1100px', // Limit width on larger screens
          height: 'auto',
          marginTop: 50,
        }}
      />
      
      {/* Full-width RTL Box */}
      <Box
        sx={{
          width: '95%',
          maxWidth: '1100px', // Limit the text box width on larger screens
          backgroundColor: '#064DA1', // Blue background color
          color: '#FFFFFF', // White text color
          padding: '10px', // Padding around the text
          textAlign: 'center', // Center align text
          marginTop: 10,
          marginBottom: 5,
          borderRadius: '10px',
          direction: 'rtl', // Set text direction to RTL
          fontFamily: "'Noto Sans Arabic', sans-serif", // Apply font family for the text box as well
        }}
      >
        <Typography 
          variant="h2" 
          sx={{ 
            fontWeight: 'bold',
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' }, // Responsive font sizes
          }}
        >
          اشتركوا بالمسابقة معكم...
        </Typography>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, // Responsive font sizes
          }}
        >
          من اليوم 2024/12/25 حتى يوم الخميس 2024/12/28
        </Typography>
      </Box>
      
      <img
        src={questionInfo}
        alt="Exam Info"
        style={{
          width: '90%', // Responsive width for the third image
          maxWidth: '1100px', // Limit width on larger screens
          height: 'auto',
          marginTop: 10,
          marginBottom:50,
        }}
      />
    </Box>
  );
}

export default QuestionSection;
